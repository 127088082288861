"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// @ts-ignore
const randomfill = require("randomfill");
const browser_hrtime_1 = require("../polyfills/browser-hrtime");
// @ts-ignore
const path = require("path-browserify");
const index_1 = require("../index");
const hrtime_bigint_1 = require("../polyfills/hrtime.bigint");
const bindings = {
    hrtime: hrtime_bigint_1.default(browser_hrtime_1.default),
    exit: (code) => {
        throw new index_1.WASIExitError(code);
    },
    kill: (signal) => {
        throw new index_1.WASIKillError(signal);
    },
    // @ts-ignore
    randomFillSync: randomfill.randomFillSync,
    isTTY: () => true,
    path: path,
    // Let the user attach the fs at runtime
    fs: null
};
exports.default = bindings;
